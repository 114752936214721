body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Bold.ttf");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.ant-tabs-tab {
  font-family: dm-sans !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #666 !important;
}

.ant-tabs-tab-active {
  color: #973f3f !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fd5c5e !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #fd5c5e !important;
}
